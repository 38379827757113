/* Mailchimp styles */
#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Poppins, Helvetica, Arial, sans-serif;
  width: 600px;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
}

body {
  font-family: poppins;
  max-width: 100vw;
  height: 100vh;
  font-size: 14px;
  background: #ffff;
  font-weight: 300;
  transition: all 0.35s ease;
}

section,
div,
span {
  transition: all 0.35s ease-in-out;
}

html {
  scroll-behavior: smooth;
}

:root {
  /* colors */
  --primary-color: hsla(14, 100%, 50%, 1);
  --primary-shade: hsla(0, 88%, 64%, 1);
  --secondary-color: hsla(225, 60%, 35%, 1);
  --secondary-variant: hsla(204, 74%, 48%, 1);
  --secondary-shade: hsla(207, 69%, 97%, 1);

  --black-color: hsla(0, 0%, 0%, 1);
  --black-variant: hsla(0, 0%, 50%, 1);

  --grey-color: hsla(0, 0%, 40%, 1);

  --white-color: hsla(240, 60%, 99%, 1);
  --light-color: hsla(0, 0%, 100%, 1);

  --brown-shade: hsl(47, 63%, 41%);

  /* font sizes */
  --f1: 37px;
  --f2: 30px;
  --f3: 20px;
  --f4: 18px;
  --f5: 16px;
  --f6: 15px;
  --f7: 14px;

  --nav-box-shadow: 0px 4px 4px hsla(0, 0%, 0%, 0.251);

  /* position */
  --poa: absolute;
}

a {
  text-decoration: none;
  color: var(--black-variant);
  transition: color 0.35s ease-in-out;
}

img {
  max-width: 100%;
}

h1 {
  font-size: var(--f1);
}

h2 {
  font-size: var(--f3);
}

input {
  width: inherit;
  outline: none;
  border: none;
  color: inherit;
  font-family: 'Poppins', Inter;
}

button.submit-btn {
  cursor: pointer;
  font-family: inherit;
}

.primary-color {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.secondary-variant {
  color: var(--secondary-variant);
}

.text-center {
  text-align: center;
}

.white-color {
  color: var(--light-color);
}

.container-lg {
  width: 86%;
  margin: 0 auto;
}

.container-md {
  width: 92%;
  margin: 0 auto;
}

.display-flex {
  display: flex;
}
.display-grid {
  display: grid;
}
.display-block {
  display: block;
}
.flex-direction-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}

.text-muted {
  color: var(--grey-color);
}

.grey {
  color: var(--grey-color);
}

.purple-shade {
  color: var(--secondary-variant_2);
}

.black {
  color: var(--black-color);
}

.black-shade {
  color: var(--black-variant);
}

.gold {
  color: var(--review);
}

.primary-shade {
  color: var(--primary-shade);
}

.line {
  width: 100px;
  height: 2px;
  background: var(--secondary-color);
}

.posititon-relative {
  position: relative;
}

/* Mobile navbar */
.mobile-nav {
  position: fixed;
  visibility: hidden;
  background: var(--white-color);
  top: 0;
  font-size: var(--f6);
  height: 100%;
  width: 60%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  opacity: 0;
  box-shadow: none;
  transform: translateX(-100%);
  transition: box-shadow 0.4s ease-in-out, opacity 0.4s ease-in-out,
    transform 0.4s ease-in-out, visibility 0.4s ease-in-out;
  will-change: box-shadow 0.4s ease-in-out, opacity 0.4s ease-in-out,
    visibility 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.mobile-nav.slide-out {
  box-shadow: 10px 2px 40px 20px rgba(0, 0, 0, 0.2);
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.mobile-nav-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 1.15rem;
}

.nav-container {
  margin-top: 0.5rem;
}

.nav-container ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.nav-container ul li a {
  font-size: var(--f6);
  font-weight: 400;
  width: 100px;
  padding-bottom: 0.5rem;
}

.mobile-nav-container .bottom {
  display: grid;
  align-items: center;
  gap: 5px;
}

.mobile-social-nav-container ul li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mobile-social-nav-container ul li a {
  height: 10px;
  width: 10px;
  padding: 0.85rem;
  border-radius: 50%;
  background: linear-gradient(
    132.35deg,
    var(--primary-color) 24.69%,
    var(--secondary-variant) 81.65%
  );
  box-shadow: -3.11579px 3.11579px 7.78947px 2.33684px
    rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
  font-size: 14px;
}

.credit-text {
  font-size: 12px;
}

/* Header */
.site-header {
  background: var(--white-color);
  /* box-shadow: var(--nav-box-shadow); */
  width: 100%;
  /* max-width: 100%; */
  /* padding: .75rem 0; */
  transition: 0.35s ease;
}

.logo-container a img {
  width: 135px;
}

.menu-container {
  transform: translateX(-170px);
}

.menu-container ul {
  gap: 5rem;
}

.menu-container ul li a {
  font-weight: 400;
  align-items: flex-end;
  gap: 6px;
}

.menu-container ul li a.active,
.menu-container ul li a:active,
.menu-container ul li a:hover {
  color: var(--secondary-variant);
}

.menu-container ul li a i {
  font-size: 10px;
  transition: all 0.35s ease-in-out;
}

.menu-container ul li:nth-child(2) a i.rotate-up {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: var(--poa);
  background: #fff;
  width: 200px;
  height: auto;
  left: 25px;
  transform: translateY(0);
  opacity: 0;
  visibility: hidden;
  padding: 1rem;
  box-shadow: 0 2px 10px 0 hsla(0, 0%, 0%, 0.1);
  transition: transform 0.35s ease-in-out,
    visibility 0.35s ease-in-out, opacity 0.35s ease-in-out;
  will-change: transform 0.35s, visibility 0.35s, opaicty 0.35s;
}

/* .dropdown-menu ul {
    display: grid;
    gap: 1rem;
} */

.dropdown-menu.is-active {
  transform: translateY(32.35px);
  visibility: visible;
  opacity: 1;
  transition: transform 0.35s ease-in-out,
    visibility 0.35s ease-in-out, opacity 0.35s ease-in-out;
}

.menu-btn {
  display: none;
  width: 45px;
  height: 35px;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
  padding: 0 1rem;
  border-radius: 1rem;
  background: linear-gradient(
    132.35deg,
    var(--primary-color) 24.69%,
    var(--secondary-variant) 81.65%
  );
  box-shadow: -3.11579px 3.11579px 7.78947px 2.33684px
    rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.35s ease-in-out;
}

.menu-btn .bar {
  gap: 5px;
  width: 24px;
  height: 2px;
  background: var(--white-color);
  transform: translateX(8px);
  transition: 0.3s ease;
}

.menu-btn .bar:nth-child(2) {
  width: 29px;
  opacity: 1;
  display: flex;
  transition: 0.3s ease;
  will-change: display 0.3s ease, opacity 0.3s ease;
}
.menu-btn .bar:nth-child(3) {
  width: 22px;
  transition: 0.3s ease;
}
.menu-btn .bar:nth-child(2).remove-second-bar {
  display: none;
  opacity: 0;
  transition: display 0.3s ease, opacity 0.3s ease;
}

.menu-btn .bar:nth-child(1).rotate-clockwise {
  width: 25px;
  margin-right: 2px;
  transform: rotateZ(45deg) translate(8px, -5px);
}

.menu-btn .bar:nth-child(3).rotate-anticlockwise {
  width: 25px;
  margin-right: 2px;
  transform: rotateZ(-45deg) translate(9px, 4px);
}

/* home-container */
.home-container {
  margin-top: 5rem;
  height: 70vh;
}

.home-content {
  /* padding: 0 10rem; */
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-content h1 {
  font-weight: 700;
}

.home-content p {
  line-height: 24px;
  font-weight: 400;
}

/* showcase */
.app-showcase {
  background: var(--secondary-shade);
  padding: 10rem 0;
  margin: 7rem 0 5rem;
}

.showcase-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.showcase-header h2 {
  position: absolute;
  top: -200px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.35px;
}

.showcase-row {
  margin-top: 4rem;
  gap: 9rem;
  transform: translateX(50px);
}

.showcase-row .showcase-img-container {
  width: 100%;
}

.showcase-row .showcase-img-container {
  position: relative;
}

.iphone-mockup {
  position: var(--poa);
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--white-color);
  width: 240px;
  height: 460px;
  border: 10px solid var(--black-color);
  border-radius: 35px;
}

.iphone-mockup::before {
  content: '';
  position: var(--poa);
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background: url(/src/assets/images/logo.svg);
  background-size: 150px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1rem;
}

.iphone-mockup::after {
  content: '';
  position: var(--poa);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--black-color);
  width: 130px;
  height: 25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.iphone-mockup:nth-child(2) {
  z-index: 1;
  transform: translateX(70px) translateY(-300px);
}

.iphone-mockup:nth-child(2)::before {
  background: url(/src/assets/images/home_banner.png);
  background-size: 200px;
  background-position: center;
  background-repeat: no-repeat;
}

.showcase .showcase-content {
  gap: 2.5rem;
}

.showcase .showcase-content h1 {
  font-size: 34px;
  line-height: 50px;
}

.showcase .showcase-content p {
  font-weight: 400;
  line-height: 24px;
  width: 380px;
  font-size: var(--f5);
}

.showcase-cta-buttons {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.showcase .showcase-content a button {
  background-color: var(--secondary-color);
  color: #fff;
  padding: 15px 30px;
  border: 0;
  font-family: poppins, sans-serif;
  border-radius: 4px;
  transition: 0.35s ease-in-out;
  cursor: pointer;
}

/* contact us */
.contact-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contact-header h1 {
  font-weight: 500;
}

.contact-header h3 {
  text-transform: uppercase;
  font-weight: 600;
}

.contact-header h1 {
  font-size: 25px;
  font-weight: 400;
}

.contact form {
  display: grid;
  align-items: center;
  width: 600px;
  padding: 1.5rem;
  border-radius: 4px;
  transition: all 0.35s ease-in-out;
}

.contact form .form-boxes {
  display: grid;
  gap: 1rem;
  width: 100%;
}

.contact form .form-boxes .input-box {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.65rem;
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid hsl(0, 0%, 87%);
  border-radius: 5px;
}

.contact form i {
  color: var(--secondary-variant);
}

textarea {
  width: 100%;
  border: 0;
  outline: none;
  resize: none;
  font-family: poppins, sans-serif;
}

textarea::placeholder {
  font-family: poppins, sans-serif;
}

.contact form .form-boxes .input-box:nth-child(4) {
  align-items: start;
}

.contact form button#contact-submit {
  margin-top: 1rem;
  padding: 12px;
  color: var(--white-color);
  border: none;
  background-color: var(--secondary-variant);
  font-family: poppins, sans-serif;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.15s ease;
}

.contact form button#contact-submit:hover {
  background: var(--primary-color);
}

/* Mailchimp */
.mailchimp-newsletter {
  padding-top: 5em;
  margin-top: 5em;
  width: 100%;
}

/* Footer */
footer {
  margin-top: 7rem;
  background: var(--secondary-variant);
  height: auto;
  color: var(--white-color);
  padding: 3rem 0 1.35rem;
}

.footer-container {
  margin-bottom: 2rem;
}

.footer-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  gap: 2rem;
}

.footer-row .footer-box:nth-child(1) {
  width: 320px;
  display: grid;
}

.footer-row .footer-box:nth-child(1) .head p {
  line-height: 21px;
  height: 80px;
}

.footer-row .footer-box:nth-child(1) .company-info {
  margin-top: 2rem;
  display: grid;
  align-items: center;
  gap: 1rem;
}

.footer-row .footer-box:nth-child(1) .company-info div {
  display: grid;
  align-items: center;
  gap: 5px;
}

.footer-row .footer-box:nth-child(1) .company-info div p,
.footer-row .footer-box:nth-child(1) .company-info p {
  letter-spacing: 1px;
  font-size: 0.9em;
}

.footer-box:nth-child(2),
.footer-box:nth-child(3),
.footer-box:nth-child(4) {
  display: flex;
  flex-direction: column;
  gap: 1.35rem;
}

.footer-box:nth-child(2) .head h3,
.footer-box:nth-child(3) .head h3,
.footer-box:nth-child(4) .head h3 {
  font-weight: 600;
  font-size: 17px;
}

.footer-box:nth-child(2) ul,
.footer-box:nth-child(3) ul,
.footer-box:nth-child(4) ul {
  display: grid;
  align-items: center;
  gap: 0.85rem;
}

.footer-box:nth-child(2) ul li a,
.footer-box:nth-child(3) ul li a,
.footer-box:nth-child(4) ul li a {
  color: var(--white-variant);
  font-size: var(--f6);
  font-weight: 400;
  font-size: 0.9em;
}

.footer-box:nth-child(4) {
  width: 350px;
}

.footer-box:nth-child(4) .head h3 {
  font-weight: 400;
}

.company-info div p.address {
  display: flex;
  flex-direction: column;
  width: 200px;
  font-size: 0.9em;
}

.footer-box:nth-child(4) .social-icons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-box:nth-child(4) .social-icons a {
  color: var(--secondary-variant);
  background-color: var(--white-color);
  font-size: var(--f5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10px;
  width: 10px;
  padding: 0.85rem;
  border-radius: 50%;
}

footer .credit {
  margin-top: 1.5rem;
  gap: 1.75rem;
}

.copyright {
  font-size: 0.9em;
}

.divider {
  width: 100%;
  max-width: 100%;
  background: var(--white-variant);
}

/* back to home */
.top-nav-button a {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  z-index: 99;
  right: 20px;
  transform: translateY(-160px);
  color: var(--brown-shade);
}

.top-nav-button i {
  font-size: var(--f6);
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: var(--white-color);
  border-radius: 50%;
  border: 2px solid grey;
  text-align: center;
}

.top-nav-button p {
  color: var(--white-color);
  font-weight: 500;
  margin-top: 5px;
}

/* media queries */
@media screen and (max-width: 1230px) {
  .footer-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 5rem;
  }
}

@media screen and (max-width: 1050px) {
}

@media screen and (max-width: 1024px) {
  .service-row {
    gap: 5rem;
    flex-direction: column;
    text-align: center;
  }

  .service-arrow {
    display: none;
  }

  .showcase {
    margin: 0 0 0;
  }
}

@media screen and (max-width: 1111px) {
  .showcase {
    margin-bottom: 10rem;
  }
  .showcase-row {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20rem;
  }

  .showcase-row .showcase-img-container {
    width: 100%;
    transform: translateX(-120px);
  }
  .showcase-row .showcase-img-container img {
    width: 750px;
  }
  .showcase .showcase-content {
    transform: translateX(0);
    width: 500px;
    gap: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

  .showcase .showcase-content p {
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    font-size: var(--f5);
  }
}

@media screen and (max-width: 991px) {
  .mobile-nav {
    visibility: visible;
  }
  .menu-container {
    display: none;
  }
  .menu-btn {
    display: flex;
  }

  .site-header {
    box-shadow: none;
  }

  .home {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-row {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 1rem;
  }

  .home-banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bubble,
  .home-banner-shadow,
  .banner-ellipse,
  .home-text-shadow {
    display: none;
  }

  .overview-container {
    margin-top: 50%;
  }

  .overview-row {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem;
  }

  .home-text .offer-text,
  .home-text .health {
    width: 100%;
  }

  .about-row {
    flex-direction: column;
  }

  .about-banner img {
    width: 100%;
  }

  .about-content {
    display: grid;
    align-items: center;
    gap: 1rem;
  }
  .about-head {
    transform: translateY(-15px);
    text-align: center;
  }

  .about-head .line {
    margin-top: 2rem;
    transform: translateX(0);
    display: flex;
    align-items: center;
    justify-self: center;
  }

  .about-title {
    width: 100%;
  }
  .about-goals {
    gap: 1.75rem;
  }
  .about-goals .goal .right {
    width: 100%;
  }

  /* services */
  .services {
    margin-top: 7rem;
  }

  .service-row {
    gap: 5rem;
    flex-direction: column;
    text-align: center;
  }
  .service-arrow {
    display: none;
  }
  .service-row .service-boxes {
    gap: 6rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
  }
  .service-row .service-boxes .service img {
    width: 50px;
  }

  /* features */
  .features {
    text-align: center;
  }

  .features .feature-head .line {
    margin-bottom: 1rem;
  }
  .features-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4rem;
  }
  .features-row .feature#second {
    transform: translateY(0);
  }
  .features-row img {
    display: none;
  }

  /* .reviews {
        display: none;
    } */
  /* FAQ Section */
  .faq {
    margin-top: 8rem;
  }

  .faq-row {
    gap: 6rem;
    flex-direction: column;
  }

  .accordion-list-item .faq-answer {
    padding: 0.5rem 0;
    line-height: 20px;
    font-weight: normal;
    width: 480px;
  }

  footer {
    width: 100%;
  }

  .footer-row {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media all and (max-width: 812px) {
  .contact-header h1 {
    font-size: 25px;
    font-weight: 500;
  }
}

@media screen and (max-width: 800px) {
  /* form {
        width: 100%;
    } */
  .footer-container {
    width: 100%;
  }
  .footer-row {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
}

@media screen and (max-width: 650px) {
  /* Mailchimp */
  #mc_embed_signup {
    width: 100%;
  }

  .overview-row {
    margin-top: 3rem;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, max-content);
    gap: 6rem;
  }

  .about-goals .goal .left {
    background-color: var(--secondary-variant);
    width: 100px;
    height: 100px;
    border-radius: 5px;
  }

  .about-goals .goal .right {
    gap: 0.5rem;
    width: 100%;
  }

  .about-goals .goal .right .goal-title {
    font-size: var(--f3);
    font-weight: 600;
  }

  .about-goals .goal .right .goal-details {
    color: var(--black-color);
    font-weight: normal;
    line-height: 18px;
  }

  .showcase-row .showcase-img-container {
    width: 100%;
  }

  .showcase .showcase-content {
    /* transform: translateX(20px); */
    width: 100%;
    gap: 2.5rem;
  }

  .showcase .showcase-content h1 {
    font-size: 30px;
    line-height: 50px;
  }

  .showcase .showcase-content p {
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    font-size: var(--f5);
  }

  .faq-row .left h1 {
    font-size: 50px;
    width: 100%;
    line-height: 75px;
  }

  .faq-row .left p {
    width: 100%;
    margin-top: 3rem;
    line-height: 22px;
    font-weight: 400;
  }

  .accordion-list-item .faq-answer {
    width: 100%;
  }

  .footer-box:nth-child(4) .social-icons {
    transform: translateX(0);
  }
}

@media screen and (max-width: 500px) {
  /* Mailchimp */
  #mc_embed_signup {
    width: 100%;
  }

  /* .showcase .showcase-img-container {
        width: 100%;
    } */
  .iphone-mockup {
    width: 200px;
    height: 400px;
    border: 8px solid var(--black-color);
    transition: all 0.35s ease-in-out;
  }

  .iphone-mockup::before {
    background: url(/src/assets/images/home_banner.png);
    background-size: 150px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 1rem;
  }

  .iphone-mockup:nth-child(1) {
    z-index: 1;
    transform: translateX(0) translateY(-200px);
  }
  .iphone-mockup:nth-child(2) {
    z-index: 1;
    transform: translateX(0) translateY(-200px);
  }

  .iphone-mockup:nth-child(2):hover {
    display: none;
  }

  .iphone-mockup:nth-child(2)::before {
    background: url(/src/assets/images/logo.svg);
    background-size: 150px;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.35s ease-in-out;
  }

  .contact form {
    width: 400px;
  }

  .contact-header h1 {
    font-size: 23px;
  }
}

@media screen and (max-width: 480px) {
  /* Mailchimp */
  #mc_embed_signup {
    width: 100%;
  }

  .service-row .service-boxes {
    gap: 6rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* grid-template-columns: repeat(2, 1fr); */
    text-align: center;
  }

  .overview-row {
    margin-top: 7rem;
  }

  .showcase {
    margin-top: 12rem;
  }

  .showcase .showcase-content {
    transform: translateX(-25px);
    width: 100%;
    gap: 2.5rem;
  }

  .showcase .showcase-content h1 {
    width: 100%;
  }

  .accordion-menu-btn h3 {
    width: 200px;
    text-align: left;
  }

  .footer-box:nth-child(4) form {
    display: grid;
    height: auto;
    gap: 8px;
  }

  .footer-box:nth-child(4) form .input-box {
    padding: 1rem;
    border-radius: 7px;
    color: var(--grey-color);
  }

  .footer-box:nth-child(4) form .input-box::placeholder {
    font-size: var(--f6);
    font-weight: 400;
  }

  .footer-box:nth-child(4) form button.submit-btn {
    width: 60px;
    border-radius: 7px;
  }
}

@media all and (max-width: 400px) {
  /* Mailchimp */
  #mc_embed_signup {
    width: 100%;
  }

  .contact form {
    width: 300px;
  }

  .contact-header h1 {
    font-size: 23px;
  }
}

@media screen and (max-width: 375px) {
  /* Mailchimp */
  #mc_embed_signup {
    width: 100%;
  }

  .logo-container a img {
    width: 100px;
  }
  .home-text .offer-text h1 {
    font-size: 30px;
    word-spacing: 6px;
  }
  .footer-box:nth-child(4) {
    width: 100%;
  }

  .about-title h2 {
    font-size: 16px;
  }

  .goal {
    flex-wrap: wrap;
    justify-content: center;
  }

  .goal-title,
  .goal-details {
    text-align: center;
  }

  .features-row .feature .small-container {
    width: 70px;
    height: 70px;
    border: 2px solid var(--primary-color);
  }

  .features-row .feature .small-container h1 {
    font-size: 600px;
    font-size: 20px;
  }

  .features-row .feature p {
    width: 100%;
  }

  .showcase .showcase-content h1 {
    font-size: 25px;
    line-height: 40px;
  }

  .faq-row .left h1 {
    font-size: 35px;
    width: 100%;
    line-height: 60px;
  }

  .footer-box:nth-child(4) form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    height: 40px;
  }

  .footer-container {
    width: inherit;
  }

  .footer-row {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .top-nav-button p {
    display: none;
  }

  .footer-box:nth-child(4) .social-icons {
    margin-top: 5rem;
  }

  .credit {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .iphone-mockup:nth-child(1) {
    z-index: 1;
    transform: translateX(-10px) translateY(-200px);
  }
  .iphone-mockup:nth-child(2) {
    z-index: 1;
    transform: translateX(-10px) translateY(-200px);
  }
}

@media screen and (max-width: 350px) {
  /* Mailchimp */
  #mc_embed_signup {
    width: 100%;
  }

  .iphone-mockup:nth-child(1) {
    z-index: 1;
    transform: translateX(-14px) translateY(-200px);
  }
  .iphone-mockup:nth-child(2) {
    z-index: 1;
    transform: translateX(-14px) translateY(-200px);
  }
  .contact form {
    width: 100%;
  }
}
